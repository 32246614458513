<template>
  <div class="params_dict_detail">
    <div class="main_box">
      <div class="main_box_con">
        <div style="position:relative;left:-18px;top: -15px;">
        </div>
        <btnComp class="btn" @click="add('addC')" v-permission="'新增'">新 增</btnComp>
        <div v-if="$route.query.code == 101398" style="color:#ff6700;margin-top:10px">注：添加快递公司需通知开发人员添加快递号对应快递公司识别逻辑</div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('editC', scope.row)" v-permission="'编辑'"
                >编辑</btnComp
              >
              <btnComp btnType="text" @click="del(scope.row.id)" v-permission="'删除'">删除</btnComp>
              <btnComp btnType="text" @click="detail(scope.row.code,scope.row.level)"  v-permission="'三级页面'">
                {{$route.query.level == 1? '三级页面':$route.query.level == 2? '四级页面':''}}
              </btnComp>
            </template>
          </el-table-column>
        </tableComp>
        <add :isShow="addShow" @close="close" :title="title" :info="info" />
        <dialogComp
          title="提示"
          :isShow="hintShow"
          @close="close"
          @sure="sureDel"
        >
          <p>确定删除吗？</p>
        </dialogComp>
      </div>
    </div>
  </div>
</template>
<script>
import add from './add'
import { dictList, dictDel } from '@/api'
export default {
  name: '',
  props: {},
  components: { add },
  data () {
    return {
      addShow: false,
      hintShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      title: '',
      info: {},
      tableData: [],
      theadName: [
        { prop: 'remark', label: '备注' },
        { prop: 'name', label: '值说明' },
        { prop: 'orderNum', label: '顺序' },
        { prop: 'code', label: '标识符' },
        { prop: 'configValue', label: '参数值' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    const { code, level } = this.$route.query
    this.code = code
    this.level = (+level) + 1
    this.getList()
  },
  watch: {
    $route (to) {
      this.code = to.query.code
      this.level = (+to.query.level) + 1
      this.getList()
    }
  },
  methods: {
    getList () {
      const params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        level: this.level,
        parentCode: this.code
      }
      dictList(params).then(res => {
        if (res.code === '1') {
          this.tableData = res.data.list
          this.total = res.data.totalCount
        }
      })
    },
    add (val, info) {
      this.title = val
      if (val === 'editC') {
        this.info = info
      }
      this.addShow = true
    },
    detail (code, level) {
      this.$router.push({ name: 'params-dict-detail', query: { code, level } })
    },
    del (id) {
      this.id = id
      this.hintShow = true
    },
    sureDel () {
      dictDel({ id: this.id }).then(res => {
        if (res.code === '1') {
          this.$message.success('删除成功')
          this.close()
        }
      })
    },
    close () {
      this.addShow = false
      this.hintShow = false
      const { code, level } = this.$route.query
      this.getList(code, level + 1)
    },
    handleSizeChange (val) {
      this.pageSize = val
      const { code, level } = this.$route.query
      this.getList(code, level + 1)
    },
    handleCurrentChange (val) {
      this.curPage = val
      const { code, level } = this.$route.query
      this.getList(code, level + 1)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
