<template>
  <dialogComp :title="titleD" :isShow="isShow" @close="close" @sure="sure">
    <el-form :model="formData" :rules="rules" ref="formData" label-width="100px">
      <inputFormComp
        label="参数名称:"
        prop="name"
        :hval="formData.name"
        @blur="nameBlur"
        placeholder="请输入参数名称"
      />
        <!-- v-if="title === 'addC' || title === 'editC'" -->
      <inputFormComp
        label="参数值:"
        prop="configValue"
        inputType="number"
        :hval="formData.configValue"
        @blur="configValueBlur"
        placeholder="请输入参数值"
      />
      <inputFormComp
        v-if="title === 'addC' || title === 'editC'"
        label="顺序:"
        prop="orderNum"
        inputType="number"
        :hval="formData.orderNum"
        @blur="orderNumBlur"
        placeholder="请输入顺序"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import { dictAdd, dictEdit } from '@/api'
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data () {
    return {
      formData: {
        name: '',
        configValue: null,
        orderNum: null,
        remark: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入参数名称', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    titleD () {
      return this.title === 'edit' ? '编辑参数' : '新增参数'
    }
  },
  watch: {
    isShow (val) {
      if (val && (this.title === 'edit' || this.title === 'editC')) {
        const { name, remark, configValue, id, orderNum } = this.info
        this.formData.name = name
        this.formData.remark = remark
        this.formData.configValue = configValue
        this.formData.orderNum = orderNum
        this.formData.id = id
      }
    }
  },
  created () {},
  mounted () {},
  methods: {
    nameBlur (val) {
      this.formData.name = val
    },
    configValueBlur (val) {
      this.formData.configValue = val
    },
    orderNumBlur (val) {
      this.formData.orderNum = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
      this.formData = {
        name: '',
        configValue: null,
        orderNum: null,
        remark: ''
      }
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { code, level } = this.$route.query
          const { name, remark, configValue, id, orderNum } = this.formData
          if (this.title === 'add' || this.title === 'addC') {
            const parmas = {
              name,
              remark,
              orderNum,
              configValue,
              parentCode: code,
              level: this.title === 'add' ? 1 : (+level) + 1
            }
            dictAdd(parmas).then(res => {
              if (res.code === '1') {
                this.$message.success('新增成功')
                this.close()
              }
            })
          } else if (this.title === 'edit' || this.title === 'editC') {
            const parmas = {
              id,
              name,
              remark,
              orderNum,
              configValue,
              parentCode: code,
              level: this.title === 'edit' ? 1 : (+level) + 1
            }
            dictEdit(parmas).then(res => {
              if (res.code === '1') {
                this.$message.success('编辑成功')
                this.close()
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
